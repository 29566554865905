<template>
  <section id="card-actions">
    <b-row>
      <!-- <b-col lg="3" md="3" sm="12">
        <b-card title="Entities">
          <b-card-text
            >Entities: <strong>{{ businesses.length }}</strong></b-card-text
          >
          <b-button
            :to="{ name: 'entities' }"
            class="mr-1 view--entity-details-btn"
            size="sm"
            variant="primary"
            >My Entities</b-button
          >
        </b-card>
      </b-col> -->
      <b-col
        lg="7"
        md="5"
        sm="12"
      >
        <b-card title="Licenses">
          <b-row>
            <b-col
              lg="4"
              sm="12"
            >
              <b-card-text>Business Licenses: <strong>{{ licenses.length }}</strong></b-card-text>
            </b-col>
            <b-col
              lg="3"
              sm="12"
            >
              <b-card-text>PSV Licenses:
                <strong>{{ psv_licenses.length }}</strong></b-card-text>
            </b-col>
            <b-col
              lg="5"
              sm="12"
            >
              <b-card-text>Broadcasting Licenses:
                <strong>{{ broadcasting_licenses.length }}</strong></b-card-text>
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <!-- <b-col lg="5" sm="12">
              <b-button :to="{ name: 'licenses' }" size="sm" variant="primary"
                >My Licences</b-button
              >
            </b-col> -->
            <b-col
              lg="7"
              sm="12"
            >
              <b-button
                class="apply-license-btn"
                :to="{ name: 'license-new' }"
                variant="primary"
                size="sm"
              >Apply for new Licence</b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        lg="5"
        md="4"
        sm="12"
      >
        <b-card title="Invoices">
          <b-row>
            <b-col
              lg="6"
              sm="12"
            >
              <b-card-text>Paid Invoices:
                <strong>{{ paid_invoices }}</strong></b-card-text>
            </b-col>
            <b-col
              lg="6"
              sm="12"
            >
              <b-card-text>Unpaid Invoices:
                <strong>{{ unpaid_invoices }}</strong></b-card-text>
            </b-col>
          </b-row>
          <b-button
            :to="{ name: 'invoices' }"
            class="mr-1 view-invoice-details-btn"
            size="sm"
            variant="primary"
          >My Invoices</b-button>
        </b-card>
      </b-col>
    </b-row>
    <b-card>
      <div class="d-flex mb-1">
        <h3>Business Licenses</h3>
        <b-button
          v-if="licenses.length"
          size="sm"
          class="view-details-btn ml-2"
          :to="{ name: 'licenses' }"
        >View All Business Licenses</b-button>
      </div>
      <p
        v-if="!licenses.length"
        class="text-align-center"
      >No Businesses Licenses</p>
      <!-- Licenses: Table -->
      <b-table
        v-else
        responsive
        :items="licenses.slice(0, 5)"
        :fields="['license_id','entity', 'tariff', 'issued_on', 'valid_until']"
      >
        <template #cell(tariff)="data">
          <b-card-text>
            {{ shortenTariff(data.item.tariff.name) }} - Ksh.{{
              new Intl.NumberFormat().format(data.item.tariff.price)
            }}
          </b-card-text>
        </template>
        <template #cell(entity)="data">
          <b-card-text class="text-nowrap">
            {{ data.item.business.name }}
          </b-card-text>
        </template>
        <template #cell(issued_on)="data">
          <b-card-text class="text-nowrap">
            {{ moment(data.item.updated_at).format("D-M-YYYY") }}
          </b-card-text>
        </template>
        <template #cell(valid_until)="data">
          <b-card-text class="text-nowrap">
            {{ moment(data.item.valid_until).format("D-M-YYYY") }}
          </b-card-text>
        </template>
      </b-table>
    </b-card>

    <b-card>
      <div class="d-flex mb-1">
        <h3>Broadcasting Licenses</h3>
        <b-button
          v-if="broadcasting_licenses.length"
          size="sm"
          class="view-details-btn ml-2"
          :to="{ name: 'licenses-broadcasting' }"
        >View All Broadcasting Licenses</b-button>
      </div>
      <p v-if="!broadcasting_licenses.length">
        No Broadcasting Licenses</p>
      <!-- Licenses: Table -->
      <b-table
        v-else
        responsive
        :items="broadcasting_licenses.slice(0, 5)"
        :fields="['license_id', 'entity', 'tariff', 'issued_on', 'valid_until']"
      >
        <template #cell(tariff)="data">
          <b-card-text>
            {{ shortenTarrif(data.item.tariff.name) }} - Ksh.{{
              new Intl.NumberFormat().format(data.item.tariff.price)
            }}
          </b-card-text>
        </template>
        <template #cell(entity)="data">
          <b-card-text class="text-nowrap">
            {{ data.item.business.name }}
          </b-card-text>
        </template>
        <template #cell(issued_on)="data">
          <b-card-text class="text-nowrap">
            {{ moment(data.item.updated_at).format("D-M-YYYY") }}
          </b-card-text>
        </template>
        <template #cell(valid_until)="data">
          <b-card-text class="text-nowrap">
            {{ moment(data.item.valid_until).format("D-M-YYYY") }}
          </b-card-text>
        </template>
      </b-table>
    </b-card>

    <b-card>
      <div class="d-flex mb-1">
        <h3>PSV Licenses</h3>
        <b-button
          v-if="psv_licenses.length"
          size="sm"
          class="view-details-btn ml-2"
          :to="{ name: 'licenses-psv' }"
        >View All PSV Licenses</b-button>
      </div>
      <p v-if="!psv_licenses.length">
        No PSV Licenses</p>
      <!-- Licenses: Table -->
      <b-table
        v-else
        responsive
        :items="psv_licenses.slice(0, 5)"
        :fields="['license_id', 'sacco', 'tariff', 'issued_on', 'valid_until']"
      >
        <template #cell(tariff)="data">
          <b-card-text class="text-nowrap">
            {{ shortenTariff(data.item.tariff.name) }} - Ksh.{{
              new Intl.NumberFormat().format(data.item.tariff.price)
            }}
          </b-card-text>
        </template>
        <template #cell(sacco)="data">
          <b-card-text class="text-nowrap">
            {{ data.item.sacco_name }}
          </b-card-text>
        </template>
        <template #cell(issued_on)="data">
          <b-card-text class="text-nowrap">
            {{ moment(data.item.updated_at).format("D-M-YYYY") }}
          </b-card-text>
        </template>
        <template #cell(valid_until)="data">
          <b-card-text class="text-nowrap">
            {{ moment(data.item.valid_until).format("D-M-YYYY") }}
          </b-card-text>
        </template>
      </b-table>
    </b-card>

    <!-- <b-card>
      <div class="d-flex mb-1">
        <h3>Entities</h3>
        <b-button size="sm" class="ml-2" :to="{ name: 'entities' }"
          >View All Entities</b-button
        >
      </div>
      <b-table
        responsive
        :items="businesses.slice(0, 5)"
        :fields="['name', 'registration_number', 'kra_pin', 'registered_on']"
      >
        <template #cell(registered_on)="data">
          <b-card-text class="text-nowrap">
            {{ moment(data.item.updated_at).format("dddd, MMMM Do YYYY") }}
          </b-card-text>
        </template>
      </b-table>
    </b-card> -->

    <b-card>
      <div class="d-flex mb-1">
        <h3>Invoices</h3>
        <b-button
          v-if="invoices.length"
          size="sm"
          class="view-details-btn ml-2"
          :to="{ name: 'invoices' }"
        >View All Invoices</b-button>
      </div>
      <p v-if="!invoices.length">
        No Invoices</p>
      <!-- Licenses: Table -->
      <b-table
        v-else
        responsive
        :items="invoices.slice(0, 5)"
        :fields="['invoice_id', 'total', 'status', 'issued_on']"
      >
        <template #cell(total)="data">
          <b-card-text class="text-nowrap">
            Ksh.{{ new Intl.NumberFormat().format(getInvoiceTotalAmount(data.item)) }}
          </b-card-text>
        </template>
        <template #cell(issued_on)="data">
          <b-card-text class="text-nowrap">
            {{ moment(data.item.created_at).format("D-M-YYYY") }}
          </b-card-text>
        </template>
      </b-table>
    </b-card>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BCardText,
  BCard,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BCardText,
    BCard,
    BButton,
  },
  data() {
    return {
      licenses: [],
      psv_licenses: [],
      broadcasting_licenses: [],
      invoices: [],
      businesses: [],
      paid_invoices: 0,
      unpaid_invoices: 0,
    }
  },
  mounted() {
    this.$http.get('/dashboard/data').then(response => {
      this.licenses = response.data.data.licenses.reverse()
      this.psv_licenses = response.data.data.psv_licenses.reverse()
      this.broadcasting_licenses = response.data.data.broadcasting_licenses.reverse()
      this.invoices = response.data.data.invoices.reverse()
      this.businesses = response.data.data.businesses.reverse()
      this.getInvoicesCount(this.invoices)
    })
  },
  methods: {
    getInvoicesCount(invoices) {
      invoices.forEach(invoice => {
        if (invoice.status === 'Paid') {
          this.paid_invoices += 1
        } else {
          this.unpaid_invoices += 1
        }
      })
    },
    getInvoiceTotalAmount(invoice) {
      let totalAmount = 0
      if (invoice.licenses.length) {
        invoice.licenses.forEach(license => {
          totalAmount += license.tariff.price
        })
      } else {
        invoice.psv_licenses.forEach(license => {
          totalAmount += license.tariff.price
        })
      }

      return totalAmount
    },
    shortenTariff(tariff) {
      if (tariff.length > 20) {
        return `${tariff.substr(0, 20)}...`
      }
      return tariff
    },
  },
}
</script>

<style scoped>
.view--entity-details-btn {
  margin-top: 20px;
}
.view-invoice-details-btn {
  margin-top: 15px;
}

.view-details-btn:hover {
  background: #BB5023 !important;
}
@media only screen and (max-width: 992px) {
  .apply-license-btn {
    margin-top: 2px;
  }
  .view--entity-details-btn {
    margin-top: 40px;
  }
  .view-invoice-details-btn {
    margin-top: 35px;
  }
}
@media only screen and (max-width: 775px) {
  .view--entity-details-btn {
    margin-top: 10px;
  }
}
</style>
